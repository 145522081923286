import store from "./store";
import {
  setAuthentication,
  logout,
  Authenticated,
} from "./reducer/authentication-slice";
import {
  NotificationSeverity,
  setNotificationMessage,
  NotificationDisplay,
  NotificationMessage,
} from "./reducer/notification-slice";
import { UserProfile, setProfile } from "./reducer/profile-slice";
import { FranchiseProfile, setFranchiseProfile } from "./reducer/franchise-profile-slice";

export default store;

export {
  setAuthentication,
  logout,
  Authenticated,
  NotificationSeverity,
  setNotificationMessage,
  NotificationDisplay,
  NotificationMessage,
  setProfile,
  UserProfile,
  FranchiseProfile,
  setFranchiseProfile
};


