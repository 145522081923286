import { createSlice } from "@reduxjs/toolkit";

export interface FranchiseProfileState {
  about: string;
  address: string;
  centerUrl: string;
  city: string;
  country: string;
  facilitiesProvided: string;
  franchiseId: string;
  hoursOfOperations: string;
  id: string | number;
  locality: string;
  longDescription: string;
  name: string;
  pincode: string;
  programsOffered: string;
  referralCode: string;
  shortDescription: string;
  specialFeatures: string;
  state: string;
  title: string;
  email: string;
  phone: string;
  accountNumber: string | number;
  bank: string;
  branch: string;
  ifscCode: string;
  nameAsPerBankAccount: string;
}

const franchiseProfileSlice = createSlice({
  name: "franchiseProfileSlice",
  initialState: {
    address: "",
    centerUrl: "",
    city: "",
    country: "",
    facilitiesProvided: "",
    franchiseId: "",
    hoursOfOperations: "",
    id: "",
    locality: "",
    longDescription: "",
    name: "",
    pincode: "",
    programsOffered: "",
    referralCode: "",
    shortDescription: "",
    specialFeatures: "",
    state: "",
    title: "",
    email: "",
    phone: "",
    accountNumber: "",
    bank: "",
    branch: "",
    ifscCode: "",
    nameAsPerBankAccount: "",
    about: "",
  } as FranchiseProfileState,
  reducers: {
    setFranchiseProfile(
      state,
      action: {
        payload: FranchiseProfileState;
      }
    ) {
      state.address = action.payload.address;
      state.centerUrl = action.payload.centerUrl;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.city = action.payload.city;
      state.facilitiesProvided = action.payload.facilitiesProvided;
      state.franchiseId = action.payload.franchiseId;
      state.hoursOfOperations = action.payload.hoursOfOperations;
      state.id = action.payload.id;
      state.locality = action.payload.locality;
      state.longDescription = action.payload.longDescription;
      state.name = action.payload.name;
      state.pincode = action.payload.pincode;
      state.programsOffered = action.payload.programsOffered;
      state.referralCode = action.payload.referralCode;
      state.shortDescription = action.payload.shortDescription;
      state.specialFeatures = action.payload.specialFeatures;
      state.state = action.payload.state;
      state.title = action.payload.title;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.accountNumber = action.payload.accountNumber;
      state.bank = action.payload.bank;
      state.branch = action.payload.branch;
      state.ifscCode = action.payload.ifscCode;
      state.nameAsPerBankAccount = action.payload.nameAsPerBankAccount;
      state.about = action.payload.about;
    },
  },
});

export default franchiseProfileSlice.reducer;

export const { setFranchiseProfile } = franchiseProfileSlice.actions;

export const FranchiseProfile = (state: {
  franchiseProfileSlice: FranchiseProfileState;
}) => state.franchiseProfileSlice;
