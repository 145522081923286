import { ReactNode } from "react";
import { State, City, IState } from "country-state-city";
import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";

const states: IState[] = State.getStatesOfCountry("IN");

export const FranchiseForm = (): FormModel[] => [
  {
    label: "Name",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "First Name",
    field: "name",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Email",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Email",
    field: "email",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
    disabled: true,
  },
  {
    label: "Phone Number",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Phone Number",
    field: "phone",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
    disabled: true,
  },
  {
    label: "Referral Code",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Referral Code",
    field: "referralCode",
    validators: [],
    responsive: { xs: 12 },
    required: false,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Center Title",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Center Title",
    field: "title",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Center Tagline",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Center Tagline",
    field: "about",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Center URL",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Center URL",
    field: "centerUrl",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
];


export const AdditionalFranchiseFields: { [key: string]: FormModel } = {
  short_desc: {
    label: "Center Short Description (50 - 100 words)",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Center Short Description",
    field: "shortDescription",
    validators: [
      {
        check: Validators.maxCharLimit,
        message: "Center Short Description cannot be greater than 1000 characters",
        num: 1000,
      },
    ],
    responsive: { xs: 12 },
    required: true,
    rows: 2,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  long_des: {
    label: "Center Long Description (200 - 500 words)",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Center Long Description",
    field: "longDescription",
    validators: [
      {
        check: Validators.maxCharLimit,
        message: "Center Long Description cannot be greater than 5000 characters",
        num: 5000,
      },
    ],
    responsive: { xs: 12 },
    required: true,
    rows: 5,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
};