import { ReactNode } from "react";
import { State, City, IState } from "country-state-city";
import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";

const states: IState[] = State.getStatesOfCountry("IN");

export const ProfileForm: FormModel[] = [
  {
    label: "First Name",
    value: "",
    size: "medium",
    autoFocus: true,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "First Name",
    field: "firstName",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Last Name",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Last Name",
    field: "lastName",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Email",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Email",
    field: "email",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
    disabled: true,
  },
  {
    label: "Phone Number",
    value: "",
    size: "small",
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "Phone Number",
    field: "phone",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
    disabled: true,
  },
];

export const AddressForm: (icon: ReactNode, state: string, showLocality?: boolean) => FormModel[] = (
  icon,
  state,
  showLocality=false
) => {
  const selected_state: IState = State.getStatesOfCountry("IN").find(
    (states) => states.name === state,
  ) as IState;
  let form = [
    {
      label: "Address Line 1",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Address",
      field: "address",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      startAdornment: icon,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "State",
      value: "",
      size: "small",
      autoFocus: false,
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "state",
      options: states.map((state) => ({
        label: state.name,
        value: state.name,
      })),
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "City",
      value: "",
      size: "small",
      autoFocus: false,
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "city",
      options: !!state
        ? City.getCitiesOfState("IN", selected_state.isoCode).map((city) => ({
            label: city.name,
            value: city.name,
          }))
        : [],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      disabled: !state,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Pin Code",
      value: "",
      size: "small",
      autoFocus: false,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "pincode",
      validators: [
        { check: Validators.pinCode, message: "Please enter a valid Pin Code" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];

  if(showLocality){
    form.push({
      label: "Locality",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Locality",
      field: "locality",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      startAdornment: icon,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    })
  }
  return form;
};


export const BankDetails: FormModel[] = [
  {
    label: "Name as per Bank Account",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "nameAsPerBankAccount",
    validators: [
      { check: Validators.Name, message: "Please enter Valid Name" },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Account Number",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "accountNumber",
    validators: [
      {
        check: Validators.number,
        message: "Please enter a valid account number",
      },
    ],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Bank Name",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "bank",
    validators: [],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "Branch",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "branch",
    validators: [],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
  {
    label: "IFSC Code",
    value: "",
    size: "small",
    autoFocus: false,
    type: "text",
    typeValue: "text",
    variant: "outlined",
    placeholder: "",
    field: "ifscCode",
    validators: [],
    responsive: { xs: 12 },
    required: true,
    sx: {
      backgroundColor: "#FFFFFF",
      marginBottom: 0,
      borderRadius: "15px",
      "& .MuiInputBase-root": {
        border: "1px solid #FFA7B7",
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    inputProps: {
      sx: {
        border: "none",
        padding: "15px",
        paddingLeft: "15px",
      },
    },
    hiddenLabel: true,
  },
];
